:root {
	--accent-color: black;
  --error-color: red;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  padding: 10px;
  margin:0 auto;
  border: 0;
  max-width: 700px;
}

.App img {
  width: 100%;
  max-width: 700px;
  margin-bottom: 25px;
}

form {
  align-items: center;
  width:100%;
  font-family: inherit;
}

.error {
  color: var(--error-color);
  margin: 0px;
}

button {
  font-size: 14px;
  width:100%;
  border: solid var(--accent-color) 1px;
  background-color: var(--accent-color);
  height:40px;
  color: #FFF;
  margin: 0px;
  padding: 10px;
  text-align:center;
  cursor: pointer;
  margin-top: 10px;
  box-sizing:border-box;
  font-family: inherit;
}

input {
  width:100%;
  border: solid #000 1px;
  height: 40px;
  padding: 10px;
  box-sizing:border-box;
  margin: 25px 0px;
  margin-bottom: 0px;
  font-family: inherit;
  font-size: inherit;
}

p {
  margin: 25px 0px;
  font-family: inherit;
}

.spinner {
  display:flex;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.spinner img {
  max-width: 120px;
  max-height: 120px;
}
